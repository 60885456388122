<template>
    <div class="custom-content">
        <el-scrollbar class="custom-wrap" v-if="customList.length >0">
            <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="content" label="基本信息" align="left">
                    <template slot-scope="scope">
                        <div class="item-content">
                            <div class="item-left">
                                <img :src="scope.row.goods_img" alt="">
                            </div>
                            <div class="item-right">
                                <div class="item-title">{{scope.row.goods_name}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="价格" align="center" width="200"></el-table-column>
                <el-table-column prop="goods_url" label="链接" align="center">
                </el-table-column>
                <el-table-column prop="fileList" label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <div class="link-box">
<!--                            <el-link type="success" :underline="false">-->
<!--                                编辑-->
<!--                            </el-link>-->
                            <el-link type="danger" :underline="false" @click="delGoodsManage(scope.row)">
                                删除
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <pagination class="pages-center" :total="customInfo.total" @current-change="handleCurrentChange"/>
        </el-scrollbar>
        <el-scrollbar class="custom-wrap" v-else>
            <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <template slot="empty">
                    <div class="no-data">
                        <div class="no-data-content">
                            <div class="img-box">
                                <img src="../../../../../assets/images/student/goods-null-data.png">
                                <span class="no-data-title">暂无商品，从商品库中导入商品</span>
                            </div>
                            <div class="btn-box">
                                <div class="create-btn" @click="importGoods">导入商品</div>
                            </div>
                        </div>
                    </div>
                </template>
                <el-table-column prop="content" label="基本信息" align="left">
                </el-table-column>
                <el-table-column prop="status" label="价格" align="center">
                </el-table-column>
                <el-table-column prop="status" label="链接" align="center">
                </el-table-column>
                <el-table-column prop="fileList" label="操作" align="center" width="400">
                </el-table-column>
            </el-table>
        </el-scrollbar>
        <el-dialog title="提示信息"
                   :visible.sync="dialogVisible"
                   width="500px"
                   :before-close="handleClose">
            <div class="dialog-content">
                <el-table class="customTable noData" :data="goodsList" @selection-change="handleSelectionChange"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column type="selection" width="100"></el-table-column>
                    <el-table-column prop="status" label="商品信息" align="left">
                        <template slot-scope="scope">
                            <div class="item-content">
                                <div class="item-left">
                                    <img :src="scope.row.goods_img" alt="">
                                </div>
                                <div class="item-right">
                                    <div class="item-title">{{scope.row.goods_name}}</div>
                                    <div class="item-text">{{scope.row.price}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination class="pages-center" :total="goodsInfo.total" @current-change="handleGoodsChange"/>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" style="margin-left: 30px;" @click="saveGoods">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import pagination from "@/components/pagination";
    import {
        stuOpLiveGoodsList,
        stuOpGoodsManagementList,
        stuOpGoodsManagementDel,
        stuOpSaveGoodsManagement
    } from "@/utils/apis"

    export default {
        name: "goodsManageIndex",
        components: {
            pagination
        },
        data() {
            return {
                customList: [],
                goodsList: [],
                customInfo: {
                    total: 0,
                    currentPageNum: 1,
                },
                goodsInfo: {
                    total: 0,
                    currentPageNum: 1,
                },
                dialogVisible: false,
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                multipleSelection: [],
            }

        },
        created() {
            this.getGoodsList();
            this.getGoodsManageList();
        },
        methods: {
            getGoodsList() {
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                    page: this.goodsInfo.currentPageNum,
                    limit: 10
                }
                stuOpLiveGoodsList(param).then(res => {
                    if (res.data !== null) {
                        this.goodsList = res.data.list
                        this.goodsInfo.total = res.data.total
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            getGoodsManageList() {
                this.customList = [];
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                    page: this.goodsInfo.currentPageNum,
                    limit: 10
                }
                stuOpGoodsManagementList(param).then(res => {
                    if (res.data !== null) {
                        this.customList = res.data.list
                        this.customInfo.total = res.data.total
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            handleCurrentChange(val) {
                this.customInfo.currentPageNum = val;
            },
            handleGoodsChange(val) {
                this.goodsInfo.currentPageNum = val;
                this.getGoodsList();
            },
            importGoods() {
                this.dialogVisible = true
            },
            resetForm() {
                this.customList[0].fileList = [];
            },
            handleClose() {
                this.dialogVisible = false;
            },
            saveGoods(){
                let ids = [];
                this.multipleSelection.forEach(item => {
                    ids.push(item.id)
                })
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                    ids: ids
                }
                stuOpSaveGoodsManagement(param).then(res => {
                    this.handleClose();
                    this.getGoodsManageList();
                }).catch(err => {
                    console.error(err)
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            delGoodsManage(row){
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    stuOpGoodsManagementDel({id: row.id}).then((res) => {
                        this.getGoodsManageList();
                        this.$message.success(res.msg)
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-dialog__wrapper {
        .el-dialog__header {
            background: #2DC079;

            span, i {
                font-size: 16px;
                color: #fff;
            }
        }

        .el-dialog__body {
            padding: 45px 0;
        }

        .el-dialog__footer {
            text-align: center;
        }
    }

    .dialog-content {
        padding: 0 20px;

        .item-content {
            display: flex;
            align-items: center;

            .item-left {
                width: 80px;
                height: 80px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .item-right {
                margin-left: 20px;
                height: 80px;

                .item-title {
                    margin-top: 17px;
                    width: 200px;
                    color: #333333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .item-text {
                    font-size: 14px;
                    color: #666666;
                    margin-top: 10px;
                }
            }
        }
    }

    .custom-content {
        margin-top: 18px;
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .create-btn {
            width: 118px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #2DC079;
            border-radius: 4px;
            color: #2DC079;
            line-height: 40px;
            cursor: pointer;
            text-align: center;
            font-size: 16px;
        }

        .custom-wrap {
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                height: 100%;

                .el-scrollbar__view {
                    margin: 0 20px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
            }

            .customTable {
                margin-top: 12px;

                &.noData {
                    ::v-deep .el-table__body-wrapper {
                        width: 100%;
                        height: 100% !important;

                        .el-table__empty-block {
                            border: unset;
                            display: flex;
                            flex-direction: column;

                            .el-table__empty-text {
                                flex: 1;
                                height: 1%;
                            }
                        }
                    }
                }

                .no-data {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .no-data-content {
                        display: flex;
                        flex-direction: column;

                        .img-box {
                            display: flex;
                            flex-direction: column;

                            .no-data-title {
                                color: #999999;
                                font-size: 16px;
                            }
                        }

                        .btn-box {
                            display: flex;
                            justify-content: center;
                        }

                        .create-btn {
                            margin-top: 40px;
                        }
                    }
                }
            }

            .item-content {
                display: flex;
                align-items: center;

                .item-left {
                    width: 80px;
                    height: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-right {
                    margin-left: 20px;

                    .item-title {
                        width: 300px;
                        color: #333333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }

            .pages-center {
                margin-bottom: 0;
            }
        }
    }
</style>